<script>
import {mask} from 'vue-the-mask'
import axios from "axios";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue"
import { useI18n } from 'vue-i18n'
import Tr from "@/i18n/translation"

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; application/json; charset=utf-8';

export default {
  name: "SmsCode",
  directives: {mask},
  props: ['value', 'mymsq'],
  components: { LanguageSwitcher },
  setup() {
    const { t } = useI18n()
    return { t, Tr }
  },

  beforeMount() {

    if(localStorage.bookingId === undefined)
      this.$router.push(Tr.i18nRoute({ name: 'Home' }));

  },
  methods: {
    resend(){
      this.getSms();
    },
    handleInput(event) {
      let value = event.target.value;
      this.emitValidate(value);
    },
    async getSms() {
      const dataSent = {
        jsonrpc: '2.0',
        method: 'BookingSystemAPI_checkPhoneNumber',
        params: {'phoneNumber': localStorage.phoneNumber},
        id: 4228
      }
      //this.msq = 'Please wait for SMS.';
      this.msq = this.t("global.wait");
      const { data } = await axios.post(this.apiDomain, JSON.stringify(dataSent));
      var result = JSON.parse(data.result);
      if(result.customerExist)
      {
        //this.msq = 'The SMS with code has been sent';
        this.msq = this.t('sms.smsSent');

        localStorage.bookingId = result.bookingId;
      }
      else
      {
        this.msq = this.t("sms.phoneNumberNotExist");
        //this.msq = 'Phone number not exist in our database.';
      }
    },
    async getAnswerSms(value) {
      this.blockEmit = true;
      this.$recaptcha("login").then((token) => {

          const dataSent = {
            jsonrpc: '2.0',
            method: 'BookingSystemAPI_getSmsCode',
            params: {'smsCode': value, 'bookingId': localStorage.bookingId, 'token': token },
            id: 4228
          }
          axios.post(
                this.apiDomain, JSON.stringify(dataSent)
            )
            .then(
                (response) => {
                  this.blockEmit = false;

                  var result = response.data.result;

                  if (result.success) {
                    this.blockEmit = false;
                    this.msq = this.t("global.wait");
                    //this.msq = 'Sms code is correct. You will be redirect to next step.';
                    //localStorage.smsCode = result.smsCode;
                    localStorage.smsCode = value;
                    if(result.isBooked)
                      this.$router.push(Tr.i18nRoute({ name: 'Confirmation' }));
                    else
                      this.$router.push(Tr.i18nRoute({ name: 'Address' }));

                  } else {
                    this.blockEmit = false;
                    this.msq = this.t("sms.failCode");
                    //localStorage.clear();
                  }
                },
                (error) => {
                  error;
                  this.blockEmit = false;
                  this.msq = this.t('global.connectionError');
                }
            );
        });
    },
    emitValidate(value) {

      if (value.length == 6) {
        //this.msq = 'Searching sms code in system.'
        this.msq = this.t("global.wait");
        if(!this.blockEmit)
          this.getAnswerSms(value);

      } else
        this.msq = this.t("sms.codeLabel");
    }
  },
  data() {
    return {
      text: '',
      msq: '',
      blockEmit: false,
    };
  },
}
</script>

<style scoped>
input {
  text-align: right;
}
</style>

<template>
  <div class="progress">
    <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0"
         aria-valuemax="100"></div>
    <div id="steps">{{ t("global.step")}} 2/5</div>
    <div class="langSwitcher">
      {{ t("global.changeLang") }}
      <LanguageSwitcher></LanguageSwitcher>
    </div>
  </div>
  <form data-bitwarden-watching="1">
    <div class="input-group mb-3" style="display:inherit; text-align: center;" >
      <h1></h1>
      <label class="form-label">{{ t("sms.label") }}</label>
    </div>
    <div class="input-group mb-3">

      <span class="input-group-text" id="basic-addon1"><i class="bi bi-code"></i></span>
      <input type="tel" class="form-control" v-mask="'######'" masked="true" autofocus placeholder="_ _ _ _ _ _"
             aria-describedby="basic-addon1"
             v-model="text" @input="handleInput"
      />
    </div>
    <div class="form-group mb-3">
      <small class="text-muted"></small>
      <div class="invalid-feedback" style="display:block;">
        {{ msq }}
      </div>
    </div>
    <div class="small">
      {{ t("sms.notReceived") }} <span @click="resend"  class="linkColor" style="cursor: pointer;">{{ t("sms.sendAgain") }} </span><br/>
      {{t("sms.or")}}
      <router-link :to="Tr.i18nRoute({ name: 'Home' })" class="linkColor"> {{ t("sms.backToPhone") }}</router-link>
    </div>
  </form>

</template>