import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './assets/main.css'
import i18n from "./i18n"
import { createApp } from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import App from './App.vue'

import { router } from './router'

let app = createApp(App);

app.config.globalProperties.apiDomain = process.env.VUE_APP_API_BASE
app.use(VueReCaptcha, { siteKey: '6Lex70cqAAAAAIDW2hhPi6QwrDZ5GUmYsycpqoBz' })
app.use(router).use(i18n).mount('#app')


import 'bootstrap/dist/js/bootstrap';