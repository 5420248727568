<script>
import axios from "axios";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import { useI18n } from 'vue-i18n'
import Tr from "@/i18n/translation"

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; application/json; charset=utf-8';

export default {
  name: "MyConfirmation",
  components: { LanguageSwitcher },
  setup() {
    const { t } = useI18n()
    return { t, Tr }
  },
  beforeMount() {
    if(localStorage.smsCode != '' && localStorage.bookingId > 0)
    {
      this.confirmBooking();
    }
    else
      this.$router.push(Tr.i18nRoute({ name: 'Home' }));
  },
  methods:{
    editBooking()
    {
      this.$router.push(Tr.i18nRoute({ name: 'Booking' }));
    },
    async confirmBooking()
    {
      const dataSent = {
        jsonrpc: '2.0',
        method: 'BookingSystemAPI_getBookingInfo',
        params: { 'smsCode': localStorage.smsCode, 'bookingId': localStorage.bookingId },
        id: 4228
      }
      const {data} = await axios.post(this.apiDomain, JSON.stringify(dataSent));
      var result = JSON.parse(data.result);

      if(result.error == 0 && result.bookingExist)
      {
        this.order = result.order;
        this.customer = result.customer;
        this.booking = result.booking;
        this.items = result.products;
        this.hours = result.allowEditHours;
        if(result.allowEdit)
        {
          document.getElementById('editBooking').style.display = "block";
        }
      }
      else if(result.error == 2)
      {
        document.getElementById('feilCodeError').style.display = "block";
        this.msq = this.t("global.feilCode");
      }
      else if(result.error == 3)
      {
        this.$router.push(Tr.i18nRoute({ name: 'Information' }));
      }
      else
      {
        this.$router.push(Tr.i18nRoute({ name: 'Information' }));
      }
    },
  },
  data(){
    return {
      order:'',
      customer: '',
      booking: '',
      items: '',
      hours: ''
    }
  },
}
</script>

<template>
  <div class="progress">
    <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    <div id="steps">{{ t("global.step")}} 5/5</div>
    <div class="langSwitcher">
      <LanguageSwitcher></LanguageSwitcher>
    </div>
  </div>
    <span class=" hcolor ">{{ t("confirmation.thanks") }} <span class="fw-bold">{{customer.name}}</span> . </span> <br/> <span class=" "> {{t("confirmation.success")}}
    <br/>{{t("booking.information")}}</span>
    <div class="alert alert-danger" id="feilCodeError" style="display: none;">{{t("global.feilCode")}}</div>
    <address class="mt-3">
      <strong><i class="bi bi-bootstrap"></i> {{t("confirmation.idLabel")}}</strong><br>
      <span class="badge bg-info text-dark" style="font-size: 16px; color: white !important;">{{ order.number }}</span>
    </address>
    <address>
      <strong><i class="bi bi-calendar"></i> {{ t("confirmation.dateLabel") }}</strong><br>
      <span class="badge bg-info text-dark" style="font-size: 16px; color: white !important;">{{ booking.date }} {{ booking.time }}</span>
    </address>
    <address class="mt-3">
      <br/>
      <h5 ><i class="bi bi-info-circle"></i> {{t("confirmation.informationLabel")}}</h5>
      <ul class="list-group" id="product-list">
        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(item, index) in items" :key="index" >
          {{ item.name }}
          <span class="badge badge-primary badge-pill">
            {{ item.quantity }}
          </span>
        </li>
      </ul>
    </address>
    <address class="mt-3">
      <h5 ><i class="bi bi-house"></i> {{ t("confirmation.addressLabel") }}</h5>
      <ul class="list-group" id="address-list">
        <li class="list-group-item d-flex justify-content-between align-items-center" >
          {{ order.address }}
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center" >
          {{ order.postcode }} {{ order.city }}
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center" >
          <abbr title="Phone">{{t("confirmation.phoneLabel")}}</abbr> {{ customer.phone }}
        </li>
      </ul>
    </address>
    <address class="mt-3">
      <h5 ><i class="bi bi-info"></i> {{ t("confirmation.additionalLabel") }}</h5>
      <ul class="list-group" id="additional-list">
        <li class="list-group-item d-flex justify-content-between align-items-center" >
          <strong>{{ t("confirmation.contactPhoneLabel")}} </strong>
          {{ booking.contactPhoneNumber }}
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center" >
          <strong>{{t("confirmation.commentLabel")}}</strong>
          {{ booking.comments }}
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center" >
          <strong>{{t("confirmation.terms")}}</strong>
          <i class="bi bi-check2-circle"></i>
        </li>
      </ul>
    </address>

    <div class="col-md-12">
      <div id="editBooking" class="small" style="display: none;">
        <!-- You can edit this order {{ hours }} hours before the booking date.<br/> -->
        <a style="cursor: pointer; " class="linkColor"  @click="editBooking">{{ t("confirmation.backToBooking") }}</a> | <router-link :to="Tr.i18nRoute({ name: 'Address' })" class="linkColor">{{ t("confirmation.backToAddress") }}</router-link>
      </div>
      <router-link :to="Tr.i18nRoute({ name: 'Home' })" class="linkColor">{{t("global.finishBooking")}}</router-link>
    </div>
</template>

<style scoped>

</style>