
<script>
import {mask} from 'vue-the-mask'
import axios from "axios";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import { useI18n } from 'vue-i18n'
import Tr from "@/i18n/translation"

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; application/json; charset=utf-8';


export default {
  name: "MyAddress",
  directives: {mask},
  components: { LanguageSwitcher },
  setup() {
    const { t } = useI18n()
    return { t, Tr }
  },
  beforeMount() {
    if(localStorage.smsCode != '' && localStorage.bookingId > 0)
      this.getAnswerBooking();
    else
      this.$router.push(Tr.i18nRoute({ name: 'Home' }));
  },
  methods: {

    async getAnswerBooking() {
      const dataSent = {
        jsonrpc: '2.0',
        method: 'BookingSystemAPI_getBookingInfo',
        params: {'smsCode': localStorage.smsCode, 'bookingId': localStorage.bookingId},
        id: 4228
      }
      const {data} = await axios.post(this.apiDomain, JSON.stringify(dataSent)).catch(error => {
        return error;
      });
      if(data == undefined)
      {
        //this.msq = 'Connection failed. Please try again later or contact with as by phone : 45 45 45 02';
        this.msq = this.t('global.connectionError');
      }
      else
      {
        var result = JSON.parse(data.result);
        if (result.bookingExist) {
          //this.msq = 'Sms code is correct. You will be redirect to next step.';
          this.customerName = result.customer.name;
          this.phone = result.customer.phone;
          this.address = result.order.address;
          this.city = result.order.city;
          this.postcode = result.order.postcode;
          this.items = result.products;
          this.job_description = result.order.jobDescription;

          //this.$router.push({name: 'Address'});
        }
        else if(result.error == 2)
        {
          document.getElementById('feilCodeError').style.display = "block";
        } else {
          //this.msq = 'An error occurred while processing the order. Please try again later or contact with as by phone : 45 45 45 02';
          this.msq = this.t("address.errorProcess");
          //localStorage.clear();
        }
      }
    },
    async confirmAddress(information) {
      const dataSent = {
        jsonrpc: '2.0',
        method: 'BookingSystemAPI_confirmAddress',
        params: {'smsCode': localStorage.smsCode, 'bookingId': localStorage.bookingId, dane:information},
        id: 4228
      };

      const {data} = await axios.post(this.apiDomain, JSON.stringify(dataSent));
      var result = JSON.parse(data.result);

      if(result.error == 1)
        this.msq = result.info;
      else if(result.error == 2)
      {
        document.getElementById('feilCodeError').style.display = "block";
        this.msq = this.t("global.feilCode");
      }
      else
      {
        //this.msq = 'Information sent successfully.';
        this.msq = this.t("global.wait");

        this.$router.push(Tr.i18nRoute({ name: 'Booking' }));
      }
    },
    submit() {
      this.msq = this.t("global.wait");
      let information = {};
      information.address = this.address;
      information.city = this.city;
      information.postcode = this.postcode;

      this.confirmAddress(information);

      //this.$router.push({ name: 'Booking' });
    }
  },
  data() {
    return {
      'customerName': '',
      'phone' : '',
      'address': '',
      'postcode': '',
      'city': '',
      'job_description': 'load...',
      'msq': '',
      'items' : [{name: 'loading...', quantity:1},]
    };
  }
}

</script>

<template>
  <div class="progress" style="margin-top:20px;">
    <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
    <div id="steps">{{ t("global.step")}} 3/5</div>
    <div class="langSwitcher">
      {{ t("global.changeLang") }}
      <LanguageSwitcher></LanguageSwitcher>
    </div>
  </div>

  <div class="row g-3">
    <div class="col-12">
        <h5 class="form-check-label "  >
          <span class="fw-bold hcolor"> {{ t("address.hello") }} {{ customerName }} </span>
        </h5>
      <br/> {{ t("address.addressLabel")}}
    </div>
    <div class="alert alert-danger" id="feilCodeError" style="display: none;">{{t("global.feilCode")}}</div>
    <div class="col-12">
      <label for="inputAddress" class="form-label">{{ t("address.phoneLabel") }}</label>
      <input type="tel" class="form-control" v-model="phone" v-mask="'###-##-###'" masked="true" placeholder="_ _ _-_ _-_ _ _"  readonly disabled >
    </div>
    <div class="col-12">
      <label for="inputAddress" class="form-label">{{ t("address.addressLabel") }}</label>
      <input type="text" class="form-control" id="inputAddress"  v-model="address" required >
    </div>

    <div class="col-md-6">
      <label for="inputZip" class="form-label">{{ t("address.postLabel") }}</label>
      <input type="text" class="form-control" id="inputZip"  placeholder="_ _ _ _" required v-mask="'####'" masked="true" v-model="postcode" >
    </div>

    <div class="col-md-6">
      <label for="inputCity" class="form-label">{{t("address.cityLabel")}}</label>
      <input type="text" class="form-control" id="inputCity" v-model="city" required >
    </div>
    <div class="col-md-12">
      <br/>
      <h5 >{{ t("address.informationLabel")}}</h5>

      <ul class="list-group" id="product-list">
        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(item, index) in items" :key="index" >
           {{ item.name }}
          <span class="badge badge-primary badge-pill">
            {{ item.quantity }}
          </span>
        </li>
      </ul>
    </div>

    <div class="col-12">
      <button type="submit" @click="submit" class="btn btn-primary">{{t("global.confirm")}}</button>
    </div>
    <div class="col-12">
      <div class="invalid-feedback" style="display:block;">
      {{ msq }}
      </div>
    </div>
    <div class="mp-3" >
      <router-link :to="Tr.i18nRoute({ name: 'Home' })" class="linkColor">{{t("global.finishBooking")}}</router-link>
    </div>
  </div>
</template>


<style scoped>
  .btn-primary{
    width: 100%;
  }
</style>